import * as React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/layout/layout";
import { Breadcrumb } from "../components/breadcrumb/breadcrumb";
import Container from "../components/container/container";
import * as styles from "./cerez-politikasi.module.scss";

const LegalPolicyPage: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout
      hideTips={true}
      seoConfig={{
        title: "Yasal Uyarı ve Kullanım Koşulları",
        description:
          "Bisolnatur'u açmadan önce iyice çalkalayın ve doğru doz için kutudan çıkan kaşığı kullanın. Öksürüğünüz öngörülen sürede geçmezse doktorunuza veya eczacınıza danışın.",
      }}
    >
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <Container>
          <section className={styles.holder}>
            <h1>Yasal Uyarı ve Site Kullanım Koşulları</h1>
            <p>
              Bu sayfada, bu web sitesi (www.bisolnatur.com.tr - bundan böyle
              “Site” olarak anılacaktır) kullanımını yöneten hüküm ve koşullar
              tanımlanmaktadır. Siteyi kullanmak isterseniz lütfen bu hüküm ve
              koşulları dikkatle okuyunuz.
              <br />
              <br />
              Hüküm ve koşullar herhangi bir anda, önceden bildirimde
              bulunmaksızın değiştirilebileceği için bu içeriğe düzenli olarak
              başvurmanızı öneririz.
            </p>
            <h2 className="orange underline">Yasal Beyan</h2>
            <br />
            <br />
            <h2 className="orange underline">1.1 Fikri Mülkiyet</h2>
            <p>
              www.bisolnatur.com.tr internet sitesi <b>(“Site”)</b>, SANOFI
              GRUBU’nun iştiraklerinden biri olan Opella Healthcare Tüketici
              Sağlığı A.Ş. (bundan sonra “Şirket” veya “Opella” olarak
              anılacaktır)’na aittir ve Şirket tarafından idare edilmektedir.
              Site’de görünen ticari markalar, logolar ve alan adları dahil
              bileşenlerin her biri ve sitenin tasarımı, fikri mülkiyete dair
              mevcut yasalarca korunmaktadır ve Sanofi’ye veya iştiraklerine
              aittir ya da üçüncü taraflarca kullanımı izne bağlıdır.
              <br />
              <br />
              Site’nin hiçbir bileşeni, herhangi bir şekilde her ne olursa olsun
              herhangi bir ortamda Şirketin daha önceden alınan yazılı oluru
              olmaksızın tamamen veya kısmen bulunulması kopyalanamaz,
              çoğaltılamaz, idare edilemez, kamuya mal edilemez, değiştirilemez,
              düzenlenemez, indirilemez, niteliksel olarak farklılaştırılamaz,
              iletilemez, dağıtılamaz ve ücretli veya ücretsiz bütününe yahut
              bir kısmına ilişkin başka bir faaliyette bulunulamaz. Fikri
              mülkiyet hakları ve diğer mülkiyet hakları ile uyum şartına bağlı
              kalınarak basın gereklilikleri için kullanım münferiden bir
              istisnadır. Sadece özel kullanım için kopyalama, kişisel, özel ve
              ticari olmayan ve kişisel bilgisayarınızda gerçekleşen kullanıma
              dair yetkilendirilmiştir.
              <br />
              <br />
              Aşağıdaki bildirim, Sitenin içeriğinin tümünün veya kısımlarının
              yetkilendirilmiş herhangi bir kopyasında gözükmelidir:
              <br />
              <br />
              "TELİF HAKKI 2018 – Opella Healthcare Tüketici Sağlığı A.Ş
              SAKLIDIR".
            </p>
            <h2 className="orange underline">1.2 Bilgilerin Niteliği</h2>
            <p>
              Bu Site, Tüketiciler için sunulmaktadır. Site’de genel olarak
              ticari bilgiler sağlanmaktadır.
            </p>
            <p>
              Sitede yayınlanan bilgiler, özellikle mali bilgiler, yatırıma
              teşvik olarak değerlendirilmez. Hiçbir durumda SANOFİ ve/veya
              ilintili alt kuruluşlarının hisselerine veya diğer menkul
              kıymetlerine dair bilgi toplanması veya bunların kamuya teklifi
              veya teberrularına, alımlarına ve değiş tokuşlarına dair bir
              teklif olarak yorumlanmamalıdır. Şirket, Sitede yer alan mali
              bilgilerin düzenli bir şekilde güncellendiği gerçeğine dikkatinizi
              çekmektedir (mali bilgiler çevrim içi temin edildiği takdirde).
              Sitede yayınlanan bilgiler, özellikle mali bilgiler, yatırıma
              teşvik olarak değerlendirilmez. Hiçbir durumda Sanofi ve/veya
              ilintili alt kuruluşlarının hisselerine veya diğer menkul
              kıymetlerine dair bilgi toplanması veya bunların kamuya teklifi
              veya teberrularına, alımlarına ve değiş tokuşlarına dair bir
              teklif olarak yorumlanmamalıdır. Şirket, Sitede yer alan mali
              bilgilerin düzenli bir şekilde güncellendiği gerçeğine dikkatinizi
              çekmektedir (mali bilgiler çevrim içi temin edildiği takdirde).
              <br />
              <br />
              Site, içeriği ile ilintili belirli bir alanda danışılan uzmanların
              fikirlerini veya basında yer alan makalelerden yapılan alıntıları
              sunabilir. Bu gibi bilgiler, sadece danışılan uzmanın görüşünü
              veya yayını temsil etmektedir ve mutlaka Opella’nın ya da SANOFI
              GRUBU’nun görüşünü yansıtmamaktadır. Bu uzmanlar Opella’nın ya da
              SANOFI GRUBU’nun çalışanları değildir ve kendilerine görüşlerinin
              Opella tarafından kullanılması karşılığında herhangi bir ücret
              ödenmemektedir. Şirket, bu gibi bilgilerin ve görüşlerin
              doğruluğundan veya eksiksiz oluşundan sorumlu değildir. Uzmanların
              görüşleri, kişisel bakış açılarını yansıtmaktadır ve hiçbir zaman
              Şirketin ve/veya <b>SANOFI GRUBU’</b>’nun görüşü veya sorumluluğu
              olarak yorumlanmamalıdır.
              <br />
              <br />
              Site’de ayrıca sağlık, fiziksel durum, tıbbi alan ve insan
              kullanımına yönelik tıbbi tedavilere ilişkin bilgiler de yer
              alabilir. Söz konusu bu tür bilgiler Site’de yalnızca
              bilgilendirme amacıyla yayınlanmaktadır ve doktorunuz ya da
              eczacınızın tavsiyelerinin yerini almaz. Bu bilgiler hiçbir
              koşulda bir hastalığın veya fiziksel problemin tıbbi tanısını
              koymak ya da Site’de sunulan ilaçları yazmak veya kullanmak için
              kullanılmamalıdır. Site üzerinden sağlıkla ilgili endişelerinizi,
              yan etkileri veya ürüne dair teknik şikayetlerinizi rapor edebilir
              ya da Opella ürünleri hakkında teknik veya tıbbi sorularınızı
              sorabilirsiniz. Bununla birlikte her durumda doktorunuza ya da
              eczacınıza başvurmanız gerekir.
            </p>
            <h2 className="orange underline">
              1.3 Diğer Sitelerle Bağlantılar
            </h2>
            <p>
              Opella veya SANOFI GRUBU, Site aracılığı ile erişilebilen bir
              üçüncü şahıs sitesi nedeniyle sorumluluk yüklenmez. Şirketten
              tamamen bağımsız olan bu gibi üçüncü şahıs sitelerinin içeriğini
              kontrol etmek üzere bir yöntemimiz mevcut değildir. Ayrıca, Site
              ile üçüncü şahıs sitesi arasındaki bağlantının varlığı, hiçbir
              durumda Şirketin herhangi bir şekilde her ne olursa olsun o
              sitenin içeriğini ve özellikle içeriğinden kaynaklanan kullanımı
              onayladığı anlamına gelmemektedir.
              <br />
              <br />
              Bunun yanı sıra, Site’den gelebilecek herhangi bir bulaşmayı,
              özellikle bir veya daha fazla bilgisayar virüsünden gelebilecek
              bulaşmaları önlemek için gerekli önlemleri almak sizin
              sorumluluğunuzdadır.
              <br />
              <br />
              Harici sitelerin Siteye hipermetin bağlantıları olabilir. Bu gibi
              bağlantılar, Şirketin daha önceden alınan açık oluru olmaksızın
              oluşturulmamalıdır. Her durumda, Şirket, bu gibi sitelerin mevcut
              olmayışından ötürü herhangi bir şekilde sorumlu değildir ve bu
              siteleri yakından takip ederek kontrol etmemekte veya
              onaylamamaktadır ve bu sitelerde yer alan veya bu siteler
              aracılığıyla edinilen içeriklerden, reklamlardan, ürünlerden veya
              diğer bileşenlerden sorumlu değildir.
            </p>
            <h2 className="orange underline">
              1.4. Sorumluluğun Sınırlandırılması
            </h2>
            <p>
              Şirket, sitede yayınlanan bilgilerin doğru ve güncel olmasını
              sağlamak üzere elinden gelenin en iyisini yapmaktadır. Şirket,
              herhangi bir zamanda daha önceden bildirimde bulunmaksızın içeriği
              düzeltme hakkını saklı tutmaktadır. Ancak, Şirket, Sitede yer alan
              bilgilerin doğru, hatasız, güncel veya eksiksiz olduğunu garanti
              edemez.
              <br />
              Sonuç olarak ve Şirketin ağır veya kasti kusurundan kaynaklanan
              mülkiyet hasarının haricinde, ikinci durum herhangi bir
              yükümlülüğü aşağıdakilerle ilgili olarak ortadan kaldırmaktadır:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● Sitede yer alan bilgilerin
              doğruluğu, hatalı oluşu veya eksikliği açısından;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● Üçüncü bir şahsın hileli girişini
              takiben Sitede yer alan bilgilerin veya maddelerin
              değiştirilmesine yol açan herhangi bir hasar açısından;
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● ve daha geniş anlamda,
              aşağıdakilerin neden olduğu bu gibi bir hasarın veya kaybın
              olasılığına dair Şirket uyarılmış olsa dahi, her ne olursa olsun
              herhangi bir nedenden ötürü, herhangi bir kökene veya niteliğe
              veya sonuçlara sahip herhangi bir mülkiyet hasarı veya
              nihayetindeki kayıp açısından:
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;i.
              Siteye ulaşım veya ulaşılamama nedeniyle,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ii.
              Bilgisayarınıza veya diğer mülkiyete bulaşabilecek herhangi bir
              virüs veya zarar dahil Sitenin kullanımı nedeniyle ve/veya,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;iii.
              Siteden doğrudan veya dolaylı olarak temin edilen herhangi bir
              bilgiye duyulan itimat nedeniyle.
              <br />
              Sitenin veya herhangi bir başka sitenin bileşenleri, zımni veya
              aleni olsun veya olmasın, herhangi bir türde garanti olmaksızın
              “olduğu gibi” temin edilmektedir. Şirket, verilen herhangi bir
              amaç doğrultusunda pazar değerlerine veya uygunluklarına ilişkin
              sınırlama olmaksızın hiçbir zımni veya aleni garanti
              sunmamaktadır.
            </p>
            <h2 className="orange underline">
              1.5 Web Sitesinin Kullanılabilirliği
            </h2>
            <p>
              a. Siteyi hata içermeksizin temin etmenin teknik olarak mümkün
              olmadığını ve Şirketin böyle bir taahhütte bulunamayacağını;
              <br />
              b. Kusurlar nedeniyle Site’nin geçici olarak kullanım dışı
              kalabileceğini ve
              <br />
              c. Sitenin işleyişinin Şirketin kontrol etmediği olaylardan
              ve/veya konulardan, örneğin sizin ve Şirket arasındaki ve Şirket
              ile diğer ağlar arasındaki iletim ve iletişim yolları yüzünden,
              etkilenebileceğini kabul etmektesiniz.
              <br />
              Şirket ve/veya tedarikçileri, herhangi bir zamanda bakımını
              üstlenmek ve/veya Sitede iyileştirmeler ve/veya değişiklikler
              yapmak üzere Sitenin tümünü veya bir kısmını geçici veya kalıcı
              olarak değiştirebilir veya bunların işleyişine ara verebilir.
              Şirket, Sitenin değiştirilmesinden, askıya alınmasından veya
              kesintiye uğratılmasından sorumlu değildir.
            </p>
            <h2 className="orange underline">1.6 Ürünlere İlişkin Bilgiler</h2>
            <p>
              Sitede dahil edilen ve yayınlanan bilgiler, SANOFI GRUBU’nun bazı
              ülkelerde veya bazı bölgelerde duyurulmayan veya mevcut olmayan
              veya farklı bir isim altında tedarik edilebilen ve ülkeye bağlı
              olarak farklılaşan yönetmeliklere ve kullanım şartlarına tabi
              olabilen Opella’ya ait ürünlere, programlara ve hizmetlere yapılan
              doğrudan veya dolaylı atıfları içerebilir. Bu gibi atıfta
              bulunmalar, Opella’nın bu ürünleri, programları veya hizmetleri
              ülkenizde satmayı amaçladığı anlamına gelmemektedir. Sizin
              kullanımınıza hazır olan ürünlere, programlara ve hizmetlere dair
              bilgiler için Şirkete veya Opella tanıtım temsilcinize başvurun.
            </p>
            <h2 className="orange underline">1.7 Yasal Hükümler</h2>
            <p>
              Site ve içerdikleri, Türkiye Cumhuriyeti Yasalarına tabidir ve bu
              konuya dair anlaşmazlıklar da, Türkiye Cumhuriyeti Mahkemelerinin
              yargı yetkisine tabidir.
            </p>
            <h2 className="orange underline">II) Özel Hüküm ve Koşullar</h2>
            <p>
              <h3 className="blue">2.1. Sitenin Yayıncısı</h3>
              Büyükdere Cad. No :193 K :4 – 10 Levent 34394 İSTANBUL – Türkiye
              Opella Healthcare Tüketici Sağlığı A.Ş., 271.862.450,00 TL
              değerinde sermaye sahibi limited ortaklıktır ve Istanbul Ticaret
              Sicili ’nde ve 452485 ile sıralanmaktadır.
              <br />
              <h3 className="blue">2.2 Yayın Yöneticisi</h3>
              Serli Çubukçu
              <br />
              <h3 className="blue">2.3 Site barındırma</h3>
              0ve1 Bilgisayar Haberleşme Elektronik Turizm San. Tic. Ltd. Şti.
              Eğitim Mah. Sadıkoğlu Plaza 5 - No:7 34722 Kadıköy / İstanbul
              T.+90 (216) 550 30 56
              <br />
              <h3 className="blue">2.4 Sahiplik ve Telif Bilgileri</h3>
              Site’de belli üçüncü taraflara ait ticari markaların kullanılması,
              Site’de yer alan içeriklerin bu ticari markaların sahipleri
              tarafından onaylandığı anlamına gelmemektedir. Söz konusu üçüncü
              taraflar, Opella ve SANOFI GRUBU’ndan bütünüyle bağımsızdır.
              <br />
              <h3 className="blue">2.5 Foto/Video Tanıtım Yazıları</h3>
              Site içindeki görseller ve videoların kullanım ve telif hakları
              Opella’ya aittir. Sayfalarda kullanılan bazı resimler stok resim
              sitelerinden ücreti mukabili temin edilmiştir.
            </p>
          </section>
        </Container>
      </Container>
    </Layout>
  );
};

export default LegalPolicyPage;
